import React from "react";
import { FaPhone, FaEnvelope, FaFax, FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="container footer-content">
        <div className="address">
          <FaMapMarkerAlt />
          <p>Toronto, Canada</p>
        </div>
        <div className="contact-info">
          <div className="contact-details">
            <div className="contact-details-div">
              <FaEnvelope /> enquiry@onsideai.org
            </div>
            <div className="contact-details-div">
              <FaPhone /> (416) 929-1103
            </div>
          </div>
        </div>
      </div>
      <div className="legal-info">
        <hr />
        <p>&copy; {currentYear} - OnSide AI</p>
        <p>
          <Link to="/privacy">Privacy Policy</Link> |{" "}
          <Link to="/disclaimer">Disclaimer</Link>{" "}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
