import React from "react";
import { FaPhone, FaEnvelope, FaFax } from "react-icons/fa";
import Navigation from "./Navigation";
import Footer from "./Footer";

export const Disclaimer = () => {
  // Get the current year
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Navigation />
      <div className="disclaimer">
        <h2>DISCLAIMER</h2>

        <p>
          The information on this website and its blogs, if any, is provided as
          a general reference work as a public service. The reader is advised to
          check for changes to current law and to consult with a qualified
          lawyer on any legal issue. The use of this website or its material
          does not create or establish a lawyer-client relationship with Janssen
          Law Professional Corporation or any of its lawyers neither does it
          create or establish Janssen Law Professional Corporation, or any of
          its lawyers, having a lawyer-client relationship with you.Although
          your privacy is a top priority for us and we take the utmost care in
          the keep all information provided from this website, directly or
          indirectly (including, but not limited to, emails, phone calls, faxes
          and form submissions) when transmitting information over this website,
          you understand and agree that Janssen Law Professional Corporation
          will have no duty to keep confidential the information. The thoughts,
          commentary and information about the law contained on this site is
          provided as a service to the community, and does not constitute
          solicitation or provision of legal advice whatsoever. This site is
          explicitly to display to and notify the public of the services our
          lawyers and law firm offer.
        </p>

        <p>
          While we endeavour to provide accurate information on this website and
          in the podcasts and blogs (if any), we cannot guarantee that the
          information provided here (or linked to from this site) is accurate,
          complete, or adequate. We provide this general legal information on an
          ‘as-is’ basis. We make no warranties and disclaim liability for
          damages resulting from its use. Legal advice must be tailored to the
          specific circumstances of each case, and laws are constantly changing,
          so nothing provided on this site or in the accompanying podcasts or
          blogs, if any, should be used as a substitute for the advice of
          competent counsel.
        </p>

        <p>
          Finally, using this website, posting on the forum, faxing, calling or
          sending an email to Janssen Law Professional Corporation, any of its
          lawyers or the host does not create a lawyer-client relationship. You
          do not become a client of Janssen Law Professional Corporation unless
          and until Janssen Law Professional Corporation agrees to act on your
          behalf and you mutually consent pursuant to a written retainer
          agreement. Additionally, any information you post to this site or
          email to Janssen Law Professional Corporation via this site shall not
          be considered confidential unless sent with prior agreement of Janssen
          Law Professional Corporation. The material on this website may be
          considered advertising under applicable rules.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default Disclaimer;
