import React from "react";
import { FaPhone, FaEnvelope, FaFax } from "react-icons/fa";
import Navigation from "./Navigation";
import Footer from "./Footer";

export const PrivacyPolicy = () => {
  // Get the current year
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Navigation />
      <div className="privacy-policy">
        <h2>Privacy Policy</h2>
        <p>
          Your privacy is important to us. Any information collected on this
          site will not be sold or shared with third parties. If this website
          contains links to other websites, we are not responsible for the
          privacy policies of third-party websites.
        </p>
        <p>
          Use of this site or contacting Janssen Law Professional Corporation
          directly or indirectly through this site in no way creates or
          establishes a lawyer-client relationship with you and Janssen Law
          Professional Corporation and any of its lawyers.
        </p>
        <p>
          This Privacy Policy governs the manner in which Janssen Law
          Professional Corporation collects, uses, maintains and discloses
          information collected from users (each, a "User") of this website (the
          "site"). This privacy policy applies to the site and all products and
          services offered by Janssen Law Professional Corporation.
        </p>
        <h3>Personal identification information</h3>
        <p>
          We may collect personal identification information from Users in a
          variety of ways in connection with activities, services, features or
          resources we make available on our site. Users may visit our site
          anonymously. We will collect personal identification information from
          Users only if they voluntarily submit such information to us. Users
          can always refuse to supply personal identification information,
          except that it may prevent them from engaging in certain site-related
          activities.
        </p>
        <h3>Non-personal identification information</h3>
        <p>
          We may collect non-personal identification information about Users
          whenever they interact with our site. Non-personal identification
          information may include the browser name, the type of computer and
          technical information about Users means of connection to our site,
          such as the operating system and the Internet service providers
          utilized and other similar information.
        </p>
        <h3>Web browser cookies</h3>

        <p>
          Our site may use "cookies" to enhance User experience. User's web
          browser places cookies on their hard drive for record-keeping purposes
          and sometimes to track information about them. The User may choose to
          set their web browser to refuse cookies or to alert you when cookies
          are being sent. If they do so, note that some parts of the site may
          not function properly.
        </p>

        <h3>Changes to this privacy policy</h3>

        <p>
          Janssen Law Professional Corporation has the discretion to update this
          privacy policy at any time and from time to time. We encourage Users
          to frequently check this page for any changes to stay informed about
          how we are helping to protect the information we collect. You
          acknowledge and agree that it is your responsibility to review this
          privacy policy periodically and become aware of modifications.
        </p>

        <h3>Your acceptance of these terms</h3>

        <p>
          By using this site, you signify your acceptance of this policy. If you
          do not agree to this policy, please do not use our site. Your
          continued use of the site following the posting of changes to this
          policy will be deemed your acceptance of those changes.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
