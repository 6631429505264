import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

// Import your page components here
import HomePage from "./components/HomePage";
import PrivacyPolicy from "./components/Privacy-Policy";
import Disclaimer from "./components/Disclaimer";
// import AboutPage from './pages/AboutPage';
// import ServicesPage from './pages/ServicesPage';
// import ContactPage from './pages/ContactPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
