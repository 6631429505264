import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaPhone, FaEnvelope } from "react-icons/fa";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const scrollToEnquiryForm = () => {
    const enquiryFormSection = document.querySelector(".enquiry-form");
    if (enquiryFormSection) {
      enquiryFormSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    toggleMenu(); // Close the menu after clicking the link
  };

  const handlePracticeAreasClick = () => {
    setShowModal(true);
    toggleMenu(); // Close the menu after clicking the link
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <nav className="navigation">
      <div className="menu-icon-wrapper" onClick={toggleMenu}>
        <div className="menu-icon">
          <div className={`hamburger ${isOpen ? "open" : ""}`}></div>
        </div>
      </div>

      <ul className={`menu ${isOpen ? "open" : "closed"}`}>
        <div className="row-wrapper">
          <div className="logo-wrapper">
            <Link to="/">
              <img
                src="https://onside-ai.s3.us-east-2.amazonaws.com/onside-textlogo.png"
                alt="OnSide AI Logo"
              />
            </Link>
          </div>
        </div>

        <div className="row-wrapper">
          <li className="contact-item email">
            <a href="mailto:enquiry@onsideai.org">
              <FaEnvelope />
              enquiry@onsideai.org
            </a>
          </li>
          <li className="contact-item phone-number">
            <a href="tel:+14169291103">
              <FaPhone />
              (416) 929-1103
            </a>
          </li>
        </div>
      </ul>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <p>This part of the website is coming soon.</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;
