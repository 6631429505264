import React, { useEffect, useRef, useState } from "react";
import Navigation from "./Navigation";
import { FaArrowRight, FaCheck } from "react-icons/fa";
import emailjs from "emailjs-com";
import Footer from "./Footer";

const HomePage = () => {
  const enquiryFormRef = useRef<HTMLDivElement>(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const scrollToEnquiryForm = () => {
    if (enquiryFormRef.current) {
      enquiryFormRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  // Scroll Animations
  useEffect(() => {
    const faders = document.querySelectorAll(".fade-in");
    const slidersLeft = document.querySelectorAll(".slide-in-left");
    const slidersRight = document.querySelectorAll(".slide-in-right");

    const options = {
      threshold: 0.1,
      rootMargin: "0px 0px -50px 0px",
    };

    const appearOnScroll = new IntersectionObserver(function (
      entries,
      appearOnScroll
    ) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        } else {
          entry.target.classList.add("show");
          appearOnScroll.unobserve(entry.target);
        }
      });
    },
    options);

    faders.forEach((fader) => {
      appearOnScroll.observe(fader);
    });

    slidersLeft.forEach((slider) => {
      appearOnScroll.observe(slider);
    });

    slidersRight.forEach((slider) => {
      appearOnScroll.observe(slider);
    });
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");
    const phone = formData.get("phone");

    const templateParams = {
      name: name,
      email: email,
      message: message,
      phone: phone,
    };

    emailjs
      .send(
        "service_ban6o18",
        "template_o3oaxpt",
        templateParams,
        "E91OotIorBbdQESyu"
      )
      .then(
        (response) => {
          console.log("Email sent successfully:", response);
          setFeedbackMessage(
            "Your message has been sent. We will get back to you soon."
          );
          setFormSubmitted(true);
        },
        (error) => {
          console.error("Error sending email:", error);
        }
      );
  };

  const handleSolutionClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="home">
      <nav className="home-header">
        <Navigation />
      </nav>

      <div className="banner">
        <picture>
          <source
            srcSet="https://onside-ai.s3.us-east-2.amazonaws.com/banner-img.png"
            media="(max-width: 768px)"
          />
          <img
            src="https://onside-ai.s3.us-east-2.amazonaws.com/banner-img.png"
            alt="OnSide AI Banner"
          />
        </picture>
        <div className="banner-text">
          <h1>OnSide AI</h1>
          <h2 className="typed-text">AI Security Solutions</h2>
        </div>
      </div>

      <main className="home-main">
        {/* Services Section */}
        <section className="services fade-in">
          <h1>Security Solutions</h1>
          <div className="security-categories">
            <div className="card car-breakins" onClick={handleSolutionClick}>
              <h3>Vehicle Break-in Prevention</h3>
            </div>
            <div
              className="card perimeter-detection"
              onClick={handleSolutionClick}
            >
              <h3>Perimeter Detection</h3>
            </div>

            <div
              className="card loitering-detection"
              onClick={handleSolutionClick}
            >
              <h3>Custom Solution Development</h3>
            </div>
          </div>
          <div onClick={scrollToEnquiryForm} className="btn-contact">
            Need Advanced Security Solutions? <FaArrowRight />
          </div>
        </section>

        {/* About Section */}
        <section className="about section-dark fade-in">
          <h2>About OnSide AI</h2>
          <div className="outline">
            <p>Proactive, AI-Powered Security Solutions.</p>
            <p>Preventing threats before they happen.</p>
            <p>
              Leverage our existing solutions or request a custom solution to
              solve your specific needs!
            </p>
          </div>
          <div onClick={scrollToEnquiryForm} className="btn-more">
            Get a Quote
          </div>
        </section>

        {/* Integration with Milestone Section */}
        <section className="integration fade-in">
          <div className="integration-content">
            <div className="integration-text">
              <h2>Seamless Integration with Milestone XProtect</h2>
              <p>
                OnSide AI is fully integrated with Milestone's XProtect,
                providing real-time alerts directly within the VMS interface.
                Security teams can view and respond to threats immediately, all
                within a familiar platform.
              </p>
            </div>
            <div className="integration-image">
              <img
                src="https://onside-ai.s3.us-east-2.amazonaws.com/milestone-logo.svg"
                alt="Milestone Logo"
              />
            </div>
          </div>
        </section>

        {/* Case Study Section */}
        {/* <section className="case-study fade-in">
          <div className="case-study-content">
            <div className="case-study-image">
              <img
                src="https://onside-ai.s3.us-east-2.amazonaws.com/resorts-world.jpg"
                alt="Resorts World Casino Las Vegas"
              />
            </div>
            <div className="case-study-text">
              <h2>Protecting Vehicles at Resorts World Casino Las Vegas</h2>
              <p>
                OnSide AI is proudly deployed at Resorts World Casino Las Vegas,
                securing their parking lots and protecting vehicles from
                break-ins. Our advanced AI security solutions proactively detect
                suspicious activities, ensuring the safety of guests and their
                property.
              </p>
            </div>
          </div>
        </section> */}

        {/* Solution Roadmap Section */}
        <section className="solution-roadmap">
          <h2>How Vehicle Break-In Prevention Works</h2>
          <div className="roadmap-steps">
            {/* Step 1 */}
            <div className="step">
              <div className="step-content slide-in-left">
                <div className="step-text">
                  <h3>Problem Identified</h3>
                  <p>
                    Suspicious persons detected loitering via real-time AI
                    analytics.
                  </p>
                </div>
                <div className="step-image">
                  <img
                    src="https://onside-ai.s3.us-east-2.amazonaws.com/detection.jpg"
                    alt="Problem - Suspicious Persons"
                  />
                </div>
              </div>
            </div>

            {/* Step 2 */}
            <div className="step">
              <div className="step-content reverse slide-in-right">
                <div className="step-text">
                  <h3>Real-time Alert</h3>
                  <p>
                    Immediate alerts are sent through Milestone's VMS, enabling
                    a quick response to the threat.
                  </p>
                </div>
                <div className="step-image">
                  <img
                    src="https://onside-ai.s3.us-east-2.amazonaws.com/security-sees-alert.jpg"
                    alt="Real-time Alert"
                  />
                </div>
              </div>
            </div>
            {/* Step 3 */}
            <div className="step">
              <div className="step-content slide-in-left">
                <div className="step-text">
                  <h3>Security Response</h3>
                  <p>
                    Security personnel can take immediate action once alerted.
                  </p>
                </div>
                <div className="step-image">
                  <img
                    src="https://onside-ai.s3.us-east-2.amazonaws.com/security-responds.jpg"
                    alt="Security Response"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Enquiry Form */}
        <section className="enquiry-form" ref={enquiryFormRef}>
          <div className="form-container">
            <h2>Get a Quote</h2>
            <p>Learn how OnSide AI can enhance your security infrastructure.</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone: (optional)</label>
                <input type="tel" id="phone" name="phone" />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" required></textarea>
              </div>
              <button
                type="submit"
                className={`submit-button ${formSubmitted ? "sent" : ""}`}
                disabled={formSubmitted}
              >
                {formSubmitted ? (
                  <>
                    <FaCheck className="icon" /> Sent
                  </>
                ) : (
                  "Get Quote"
                )}
              </button>
              {feedbackMessage && (
                <div className="feedback-message">{feedbackMessage}</div>
              )}
            </form>
          </div>
        </section>
      </main>

      {/* Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <p>This feature is coming soon. Stay tuned for updates!</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default HomePage;
